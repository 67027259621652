<template>
  <el-container>
    <el-header>
      <div>
        <head01></head01>
      </div>
    </el-header>
    <el-main>
      <div class="page-box">
        <div>
          <el-card>
            <div class="bc_Div01">
              <img src="../../assets/imgs/news.png" alt="" />
            </div>
          </el-card>
        </div>
        <div>
          <div class="content_container">
            <div class="content_text">
              <div class="content_1">
                <h2>{{ content.title }}</h2>
                <p>
                  {{ content.paragraph1 }}
                </p>
                <img :src="imgUrl1" />

                <p>
                  {{ content.paragraph2 }}
                </p>
                <img :src="imgUrl2" />

                <p>
                  {{ content.paragraph3 }}
                </p>
                <p>
                  {{ content.paragraph4 }}
                </p>
                <p>
                  {{ content.paragraph5 }}
                </p>
                <img :src="imgUrl3" />
                <div class="content_footer">
                  <h1>关注公众号，了解更多</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer style="height: 200px; background-color: #507dc0">
      <div>
        <foot01></foot01>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Header01 from "../Header01.vue";
import Foot01 from "../Foot01.vue";
export default {
  data() {
    return {
      activeName: "first",
      imgUrl1: require("../../assets/imgs/news/01.jpg"),
      imgUrl2: require("../../assets/imgs/news/02.jpg"),
      imgUrl3: require("../../assets/imgs/news/03.jpg"),
      content: {
        title:
          "南京铭和医院投资管理有限公司张汉乐董事长莅临江苏淮海数码科技有限公司视察工作",
        paragraph1:
          "2021年6月17日上午，南京铭和医院投资管理有限公司张汉乐董事长莅临公司所属江苏淮海数码科技有限公司视察工作。夏志学总经理、张鋆经理等陪同视察。",
        paragraph2:
          "随后，夏志学总经理从公司文化建设、规章制度的建立健全、业务建设所取得的成绩及存在不足几个方面进行了工作汇报；研发部张鋆经理对前阶段沭阳铭和康复医院、江苏淮海数码科技有限公司网站的研发、公司宣传册及“铭和健康”公众号的设计等方面进行了展示说明。技术组组长彭敬召作了部分补充。",
        paragraph3:
          "张汉乐董事长对江苏淮海数码科技有限公司前期的工作开展给予了充分的肯定，并对下一步工作作出明确的指示：一是做好已研发OA系统（江科软件）的销售及维护工作，协助江苏科技大学把OA系统（江科软件）二期工程做好；二是做好医养结合管理软件的研发，并确保通过国家级验收；三是为更多的中小企业服务。",
        paragraph4:
          "最后，夏志学总经理代表公司作表态发言：坚决按照张董事长要求不折不扣抓好各项工作落实，确保完成任务。",
        paragraph5:
          "当天下午，江苏华方医疗集团有限公司曹振方总经理、李政主任一行也莅临江苏淮海数码科技有限公司，就“医养结合”领域的合作事宜进行了深层探讨与交流。",
      },
    };
  },
  components: {
    head01: Header01,
    foot01: Foot01,
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.el-main {
  padding: 0;
}
.page-box {
  //   height: 100vh;
  margin-top: 10px;
}
//
.bc_Div01 {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
}

// 产品内容样式
.content_container {
  max-width: 1600px;
  margin: auto;
}
.content_text {
  display: flex;
  justify-content: center;
}
.content_1 {
  width: 65%;
}
.content_1 > h2 {
  margin: 20px 0;
  font-size: 20px;
  color: #003399;
}
.content_1 > p {
  font-size: 15px;
  line-height: 28px;
  text-indent: 2em;
  margin: 15px 0;
  text-align: justify;
  text-justify: inter-ideograph;
}
.content_1 > img {
  display: flex;
  justify-content: center;
  width: 70%;
  align-items: center;
  margin: 0 auto;
}
.content_footer {
  text-align: center;
  margin: 15px;
}
</style>