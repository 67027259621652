<template>
  <el-container>
    <el-header style="margin-top:10px;">
      <div>
        <div class="header_Div">
          <el-row>
            <!-- 公司标志 -->
            <el-col :span="6">
              <div>
                <a href="javascript:;">
                  <img src="../assets/hhlogo2.jpg" />
                </a>
              </div>
            </el-col>
            <el-col :span="14">
              <!-- NavMenu 导航菜单 -->
              <el-menu class="elmenu_Index" mode="horizontal" router>
                <el-menu-item index="index" class="menu_Style"
                  ><a href="javascript:;" class="index_02a"
                    >首页</a
                  ></el-menu-item
                >
                <!-- <el-submenu index="2">
                  <template slot="title"  >产品中心</template>
                  <el-menu-item index="oa_office"> OA办公系统</el-menu-item>
                  <el-menu-item index="rehabilitation">铭和医疗养老平台</el-menu-item>
                  <el-menu-item index="minghe">铭和健康平台</el-menu-item>
                </el-submenu> -->
                <el-menu-item index="oa_office" class="menu_Style"
                  ><a href="javascript:;" class="index_02a"
                    >产品中心</a
                  ></el-menu-item
                >
                <el-menu-item index="news" class="menu_Style"
                  ><a href="javascript:;" class="index_02a"
                    >新闻中心</a
                  ></el-menu-item
                >
                <!-- <el-menu-item index="solve" class="menu_Style"
                  ><a href="javascript:;" class="index_02a"
                    >解决方案</a
                  ></el-menu-item
                > -->
                <el-menu-item index="recruit" class="menu_Style"
                  ><a href="javascript:;" class="index_02a"
                    >人才招聘</a
                  ></el-menu-item
                >
                <el-menu-item index="about" class="menu_Style"
                  ><a href="javascript:;" class="index_02a"
                    >关于淮海</a
                  ></el-menu-item
                >
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-header>
  </el-container>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.header_Div {
  max-width: 1600px;
  margin: 0 auto;
}

.index_02a {
  text-decoration: none;
}
.elmenu_Index {
  float: right;
  height: 100%;
}
.el-menu.el-menu--horizontal {
  border-bottom-width: 0px;
}
.menu_Style {
  font-size: 16px;
  padding: 0px 30px 10px 30px;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
</style>