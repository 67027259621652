<template>
  <el-container>
    <el-header>
      <div>
        <head01></head01>
      </div>
    </el-header>
    <el-main>
      <div class="page-box">
        <el-card>
          <div>
            <div class="bc_Div01">
              <div>
                <div>
                  <el-row>
                    <el-col :span="6">
                      <div id="product_img01">
                        <img src="../../assets/imgs/product/OApro.jpg" alt="" />
                      </div>
                    </el-col>
                    <el-col :span="16">
                      <div class="pro_Text2">
                        <h2>铭和OA智慧平台</h2>
                        <p>
                          铭和OA办公系统是由江苏淮海数码科技有限公司和江苏科大汇峰科技有限公司联合研发的以医院办公自动化为方向的综合管理办公平台。
                          通过工作数据可视化管理、权限分配等有效改善管理模式，实现资源的优化配置和高效运转，全面提升企业综合管理水平和竞争力。
                        </p>
                        <el-button type="primary">联系销售</el-button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="_blank"></div>
            <div class="content_container">
              <div class="content_text">
                <div class="content_1">
                  <h2>产品概述</h2>
                  <p>
                    铭和OA办公系统是由江苏淮海数码科技有限公司和江苏科大汇峰科技有限公司联合研发的以医院办公自动化为方向的综合管理办公平台。
                    铭和OA办公系统通过融合不同信息化资源，提供包括流程审批、行政办公、日常事务、数据统计分析、即时通讯、移动办公等服务，
                    帮助企业降低沟通和管理成本，提升生产和决策效率。
                  </p>
                  <p>
                    通过工作数据可视化管理、权限分配等有效改善管理模式，实现资源的优化配置和高效运转，全面提升企业综合管理水平和竞争力。
                  </p>
                  <p>
                    通过工作数据可视化管理、权限分配等有效改善管理模式，实现资源的优化配置和高效运转，全面提升企业综合管理水平和竞争力。
                  </p>
                  <h2>产品特性</h2>
                  <h3>协同高效</h3>
                  <p>
                    提供统一通讯功能，多端消息同步更新，随时随地交流工作，提高个人效能。
                    集行政办公于一体，多部门统一协调，提高办事效率。
                  </p>
                  <h3>智慧办公</h3>
                  <p>
                    支持AI语音识别，人工智能操控。
                    用户可根据使用习惯或者具体的业务场景，个性化搭建属于自己的工作台。
                  </p>
                  <h3>按需定制</h3>
                  <p>
                    报表数据、流程表单、数据分析图谱和应用中心开放编辑，全面适配企业个性化定制。
                  </p>
                  <h3>开放互联</h3>
                  <p>
                    应用中心、业务流程平台、移动办公平台等多用户端开放，数据互联。
                  </p>
                  <h2>功能特色</h2>
                  <h3>知识管理</h3>
                  <p>
                    提供公共文件柜、网络硬盘和共享图片功能。公共文件柜拥有完整的文件体系，支持多视图模式显示，支持签阅情况可视化显示。
                    通过设置名称、容量和共享路径实现用户自定义网络硬盘功能。共享图片可定义图片名称、目录路径，通过部门、角色和人员来限定发布范围。
                  </p>
                  <h3>排班管理</h3>
                  <p>
                    排班信息可视化显示，提供多种排班模式，支持排班表导入导出，自定义值班查询，排班信息多端事务提醒。
                  </p>
                  <h3>流程中心</h3>
                  <p>
                    提供工作查询、工作监控、超时统计、工作委托、数据报表和工作流自定义等功能。支持表单与流程自定义、表单导入导出、流程设计。
                    实现了移动审批、数据监控和全程无纸化办公。
                  </p>
                  <h3>行政办公</h3>
                  <p>
                    提供公告与新闻管理、投票管理、办公用品、会议管理、车辆申请、固定资产和资源申请等功能。
                  </p>
                  <div class="content_footer">
                    <h1>更多信息，邀您体验</h1>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </el-card>
      </div>
    </el-main>
    <div>
      <foot01></foot01>
    </div>
  </el-container>
</template>

<script>
import Header01 from "../../components/Header01.vue";
import Foot01 from "../../components/Foot01.vue";
export default {
  data() {
    return {
      activeName: "first",
    };
  },
  components: {
    head01: Header01,
    foot01: Foot01,
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.el-main {
  padding: 0;
}
.page-box{
  // height: 100vh;
  margin-top: 10px;
}

._blank {
  height: 20px;
}

.bc_Div01 {
  width: 90%;
  height: 300px;
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  background-color: #effbfb;
}
.bc_Div01 > div {
  padding: 30px 0;
}
#product_img01 {
  text-align: right;
  padding-top: 20px;
}
#product_img01 > img {
  padding-right: 70px;
}
.pro_Text2 > h2 {
  padding: 15px 0;
  font-size: 26px;
}
.pro_Text2 > p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 15px;
}
// 产品内容样式
.content_container {
  max-width: 1600px;
  margin: auto;
}
.content_text {
  display: flex;
  justify-content: center;
}
.content_1 {
  width: 70%;
}
.content_1 > h2 {
  margin: 20px 0;
  font-size: 24px;
  color: #003399;
}
.content_1 > h3 {
  text-indent: 1em;
}
.content_1 > p {
  font-size: 14px;
  line-height: 28px;
  text-indent: 2em;
  margin: 15px 0;
}
.content_footer{
  text-align: center;
  margin: 25px;
}
</style>