<template>
  <el-container>
    <el-footer
      style="height: 200px; background-color: #507dc0; padding: 10px 0 10px"
    >
      <div class="container">
        <el-row :gutter="10" type="flex" justify="center">
          <el-col :span="6">
            <div>
              <div class="footer_Title">
                <img class="footer_Img" src="../assets/imgs/service.png" />
                <div>服务热线</div>
                <div>025-52389832</div>
              </div>
              <div class="service-Entry">
                <ul class="col_Ul1">
                  <li>技术咨询</li>
                  <li>成功案例</li>
                  <li>售后服务</li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="7">
            <div>
              <div>
                <div class="footer_Title">淮海科技</div>
                <div>
                  <ul class="col_Ul">
                    <li>微信公众号</li>
                    <li>
                      <img class="footer_Img2" src="../assets/MH.jpg" alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </el-col>
          <!-- <el-col :span="4">
            <div></div>
          </el-col> -->
          <el-col :span="7">
            <div>
              <div class="footer_Col3">
                <div class="col_03">
                  <ul class="col_Ul3">
                    <li>公司地址: 江苏省南京市玄武区傅厚岗1号富昇大厦4F</li>
                    <li>邮编: 210008</li>
                    <li>电话: 025-52389832</li>
                    <li>传真: 025-52389832</li>
                  </ul>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-footer>
    <div class="keep-on-record">
      <div class="record-box">
        <span>技术支持：江苏淮海数码科技有限公司</span>
        <span>网站备案号：<a href="http://beian.miit.gov.cn/">苏ICP备2021040407号-1</a></span>
        <span>IE浏览器建议10以上版本</span>
      </div>
    </div>
  </el-container>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
// footer div设置
.container {
  max-width: 1400px;
  margin: 0 auto;
  // display: flex;
  // justify-content: center;
}
//footer img
.footer_Img {
  width: 60px;
  float: left;
  margin-right: 20px;
}

.footer_Title {
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
}
.service-Entry {
  font-size: 14px;
  display: block;
}
.col_Ul {
  list-style: none;
  display: block;
  line-height: 32px;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  font-size: 14px;
}

//微信公众号样式
.footer_Img2 {
  width: 100px;
  height: 100px;
  margin-top: 10px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.col_Ul1 {
  list-style: none;
  display: block;
  line-height: 32px;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  font-size: 14px;
  text-align: left;
}
.col_03 {
  margin-top: 10px;
}
.col_Ul3 {
  list-style: none;
  display: block;
  line-height: 32px;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  font-size: 14px;
  text-align: left;
}
.el-col {
  border-radius: 4px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

// 底部备案样式
.keep-on-record {
  max-width: 1800px;
  min-width: 1400px;
  height: 20px;
  margin: 0 auto;
}
.record-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.record-box span {
  width: 33.3%;
  font-size: 12px;
}
.record-box a{
  text-decoration: none;
  color: #000;
}
</style>