<template>
  <el-container>
    <el-header>
      <div>
        <head01></head01>
      </div>
    </el-header>
    <el-main>
      <div class="page-box">
        <div>
          <el-card>
            <div class="bc_Div01">
              <img src="../../assets/imgs/news.png" alt="" />
            </div>
          </el-card>
        </div>
        <div>
          <div class="content_container">
            <div class="content_text">
              <div class="content_1">
                <h2>{{ content.title }}</h2>
                <p>
                  {{ content.paragraph1 }}
                </p>
                <img :src="imgUrl1" />

                <p>
                  {{ content.paragraph2 }}
                </p>
                <img :src="imgUrl5" />

                <p>
                  {{ content.paragraph3 }}
                </p>
                <img :src="imgUrl2" />

                <p>
                  {{ content.paragraph4 }}
                </p>
                <img :src="imgUrl4" />
                <div class="content_footer">
                  <h1>关注公众号，了解更多</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer style="height: 200px; background-color: #507dc0">
      <div>
        <foot01></foot01>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Header01 from "../Header01.vue";
import Foot01 from "../Foot01.vue";
export default {
  data() {
    return {
      activeName: "first",
      imgUrl1: require("../../assets/imgs/news/QQ图片20210903154344.jpg"),
      imgUrl2: require("../../assets/imgs/news/QQ图片20210901180611.jpg"),
      imgUrl4: require("../../assets/imgs/news/QQ图片20210901180655.jpg"),
      imgUrl5: require("../../assets/imgs/news/QQ图片20210901180716.jpg"),
      content: {
        title: "“铭和健康管理系统”研讨会",
        paragraph1:
          "为了推动和完善“铭和健康管理系统”的研发工作，2021年9月1日下午3时，江苏淮海数码科技有限公司召开“铭和健康管理系统”视频研讨会。江苏淮海数码科技有限公司执行董事张宁远、总经理夏志学、副总经理王军等公司相关人员出席会议。沭阳铭和医院副院长桑红娟、沭阳铭和医院副院长、体检中心主任夏晓芳、沭阳铭和康复医院副院长刘志军、王振球、沭阳铭和康复医院副院长、养老服务中心主任刘海宁等应邀出席会议。南京铭和医院投资管理有限公司董事长张汉乐到会并作重要讲话。会议由淮海科技总经理夏志学主持。",
        paragraph2:
          "会上，研发部经理张鋆向大家汇报了“铭和健康管理系统”的框架构成及界面效果展示，对“铭和健康管理系统”的整体功能和不同阶段将实现的目标做了较详细的描述。张鋆经理指出“铭和健康管理系统”将以微信小程序为主要载体，提供预约挂号、体检预约、智能导医、快捷缴费、报告查询、日常运动等功能，致力于提高患者就医效率，提升患者入院就医体验，合理分配门诊及体检中心资源。未来，将利用敏捷开发的概念，提高产品功能的迭代效率，快速响应医院与患者的需求。",
        paragraph3:
          "参会领导和专家们就张鋆经理的汇报和介绍共同参与研讨，提出修改意见，以便淮海科技研发人员系统梳理，研究采纳。随后，淮海科技执行董事张宁远对公司下一步工作作出强调和部署，指出“铭和健康管理系统”研发工作重心应放在流程管理上，主要解决体检分类和信息录入及后续处理问题；互动模块以智能客服形式实现；消息推送方面做到多平台互通；系统设置特殊病种动态入口，如新冠肺炎专区等。",
        paragraph4:
          "最后，南京铭和医院投资管理有限公司张汉乐董事长作重要讲话。他首先对江苏淮海数码科技有限公司前阶段的工作给予了充分肯定，同时指出体检模块重点工作是流程管理，解决团检信息快速录入等问题；饮食和运动模块是后续的工作，互动模块放在最后。要求涉及到“铭和健康管理系统”的各个部门要积极配合淮海科技的研发工作，明确各部门业务流程和需要实现的功能，再与淮海科技研发部紧密沟通，争取早日、优质完成“铭和健康管理系统”的研发工作。",
      },
    };
  },
  components: {
    head01: Header01,
    foot01: Foot01,
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.el-main {
  padding: 0;
}
.page-box {
  //   height: 100vh;
  margin-top: 10px;
}
//
.bc_Div01 {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
}

// 产品内容样式
.content_container {
  max-width: 1600px;
  margin: auto;
}
.content_text {
  display: flex;
  justify-content: center;
}
.content_1 {
  width: 65%;
}
.content_1 > h2 {
  margin: 20px 0;
  font-size: 20px;
  color: #003399;
}
.content_1 > p {
  font-size: 15px;
  line-height: 28px;
  text-indent: 2em;
  margin: 15px 0;
  text-align: justify;
  text-justify: inter-ideograph;
}
.content_1 > img {
  display: flex;
  justify-content: center;
  width: 70%;
  align-items: center;
  margin: 0 auto;
}
.content_footer {
  text-align: center;
  margin: 15px;
}
</style>