<template>
  <el-container>
    <el-header>
      <div>
        <head01></head01>
      </div>
    </el-header>
    <el-main>
      <div class="page-box">
        <!-- <el-card>
          <div>
            <div class="bc_Div01"></div>
          </div>
        </el-card> -->
        <div class="bc_Tabs">
          <h1 class="main-title">AI科技·创造未来：淮海科技推出DeepSeeK本地化部署方案</h1>
          
          <div class="content-section">
            <p class="intro-text">
              当人工智能与医疗健康深度融合，会碰撞出怎样的火花？
            </p>
            <p class="date-text">
              1月20日，新一代推理模型DeepSeek-R1正式发布，淮海科技高度关注，经过1个月的研发调试，于2月20日推出DeepSeek本地化部署最优方案，并在沭阳铭和医院成功本地化部署，部署后宿迁市卫健委、宿迁市官媒争相报道，AI e疗产品经理知识库、基层好医声、高通资讯等公众号汇总转发，累积宣传阅读量达20余万次，医疗机构尽早抓住风口，就是抓住了一次极好的宣传机会。
            </p>

            <h2 class="section-title">DeepSeek本地化部署给您带来社会效益：</h2>
            <div class="benefits">
              <p>本地化部署通过技术赋能与模式创新，在医疗公平、服务普惠、科研突破和数据安全等领域创造了显著社会价值。</p>
              
              <h3>1、基层医疗能力提升：</h3>
              <p>通过本地化部署打造专科AI模块，支持基层医生实时调取跨学科知识，辅助临床决策，推动优质资源向县域下沉。</p>
              
              <h3>2、智慧医院品牌效应：</h3>
              <p>部署DeepSeek可提升医院“数智化”形象，吸引政策支持与合作资源。</p>
              
              <h3>3、公共卫生防控能力升级</h3>
              <p>支持疾病趋势预测与健康科普，例如结合气候数据预警传染病风险，并通过AI生成通俗化健康指导内容。</p>
            </div>

            <h2 class="section-title">DeepSeek本地化部署给您带来经济效益：</h2>
            <p>本地化部署通过效率优化、成本控制、收入增长和风险规避四重路径，为医院创造直接与间接经济效益。</p>

            <div class="economic-benefits">
              <h3>1、提升医疗服务质量与效率：</h3>
              <p>DeepSeek辅助可节省医生30%的文书时间，并在重点科室实现复杂病例的精准诊断，降低误诊漏诊风险。</p>
              
              <h3>2、科研效率革命性提升：</h3>
              <p>通过DeepSeek快速提取文献核心观点，缩短科研调研时间，加速科研成果转化。</p>
              
              <h3>3、提升运营效率，优化患者就医体验：</h3>
              <p>DeepSeek智能服务（如24小时客服、个性化健康管理）提升患者满意度，间接增加医院口碑和就诊量。DeepSeek通过智能导诊、预问诊和报告解读功能，减少患者候诊时间，提升门诊效率。同时DeepSeek辅助影像分析可减少重复检查，降低设备损耗。</p>
            </div>

            <div class="contact-section">
              <p>技术为舟，服务为桨，淮海科技现愿意为您提供多种本地化部署方案，部署周期2个工作日，亦可为您量身定制AI智能客服、智能健康助手等更多功能。让人工智能与您的医院深度融合，在患者服务、临床支持、科研创新等领域实现跨越式升级，以“AI+医疗”的创新模式，开启属于您的智慧医疗新时代！</p>
              <p class="contact-info">联系电话：13023355068</p>
              <p class="contact-info contact-info-weixin">微信联系：<img class="erweima_Img" src="../assets/erweima01.png" alt="" /></p>
              <p class="contact-info">关注淮海科技了解更多</p>
              <img class="erweima_Img" src="../assets/erweima02.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer style="height: 200px; background-color: #507dc0">
      <div>
        <foot01></foot01>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Header01 from "./Header01.vue";
import Foot01 from "./Foot01.vue";
export default {
  data() {
    return {
      activeName: "first",
    };
  },
  components: {
    head01: Header01,
    foot01: Foot01,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.header_Div {
  max-width: 1600px;
  margin: 0 auto;
}
.el-main {
  padding: 0;
}

.page-box {
  // height: 100vh;
  margin-top: 10px;
}
._blank {
  height: 20px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
//
.bc_Div01 {
  width: 90%;
  height: 300px;
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  background-color: #effbfb;
}

.bc_Tabs {
  width: 65%;
  min-height: 300px;
  margin: 40px auto;
  padding: 20px;
}
// *about页面tab导航文字样式
.about-tab-style {
  // background-color: blue;
  font-size: 18px;
}


.tab-pane-box {
  padding: 5px 40px;
  width: 80%;
  height: 470px;
}
.tab-pane-title{
  font-weight: 700;
  font-size: 20px;
}

.tab-pane-box > p {
  margin-top: 10px;
  text-indent: 2em;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 20px;
}
.tab-pane-box > img {
  width: 100%;
  height: auto;
}

.main-title {
  text-align: center;
  font-size: 36px;
  margin-bottom: 30px;
}

.content-section {
  line-height: 1.6;
  
  p {
    font-size: 18px;
  }
}

.intro-text {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.date-text {
  margin-bottom: 30px;
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  margin: 25px 0 15px 0;
}

.benefits, .economic-benefits {
  margin: 20px 0;
  
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 15px 0 10px 0;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.contact-section {
  margin-top: 30px;
  
  p {
    font-size: 18px;
  }
  
  .contact-info {
    margin-top: 20px;
    font-weight: bold;
    font-size: 20px;
  }
  .contact-info-weixin {
    display: flex;
  }
}
//微信公众号样式
.erweima_Img {
  width: 100px;
  height: 100px;
}
</style>