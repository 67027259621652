import Vue from 'vue'
import VueRouter from 'vue-router'
//1.导入index组件
import Index from '../components/Index.vue'

import OA_office from '../components/products/OA_office.vue'
import Recruit from '../components/Recruit.vue'
import About from '../components/About.vue'
import News from '../components/News.vue'
import DeepSeeK from '../components/DeepSeeK.vue'
import DeepSeeKH5 from '../components/DeepSeeKH5.vue'
import new20210901 from '../components/news/new20210901.vue'
import new20210630 from '../components/news/new20210630.vue'
import new20210917 from '../components/news/new20210917.vue'
import new20210926 from '../components/news/new20210926.vue'




Vue.use(VueRouter)

const routes = [

]

const router = new VueRouter({
  mode:'hash',
  routes: [

    // 
    //2. 注册路由
    {
      path: '/',
      component: Index,
      meta: {
        title: '江苏淮海数码科技有限公司'
      }
    },
    {
      path: '/index',
      component: Index,
      meta: {
        title: '淮海科技'
      }
    },
    {
      path: '/oa_office',
      component: OA_office,
      meta: {
        title: '产品中心'
      }
    },
    
    {
      path: '/recruit',
      component: Recruit,
      meta: {
        title: '人才招聘'
      }
    },
    {
      path: '/about',
      component: About,
      meta: {
        title: '关于淮海'
      }
    },
    {
      path: '/news',
      component: News,
      meta: {
        title: '新闻中心'
      }
    },
    {
      path: '/DeepSeeK',
      component: DeepSeeK,
      meta: {
        title: '关于DeepSeeK'
      }
    },
    {
      path: '/DeepSeeKH5',
      component: DeepSeeKH5,
      meta: {
        title: '关于DeepSeeKH5'
      }
    },
    {
      path: '/new20210901',
      component: new20210901,
      meta: {
        title: '新闻'
      }
    },
    {
      path: '/new20210630',
      component: new20210630,
      meta: {
        title: '新闻'
      }
    },
    {
      path: '/new20210917',
      component: new20210917,
      meta: {
        title: '新闻'
      }
    },
    {
      path: '/new20210926',
      component: new20210926,
      meta: {
        title: '新闻'
      }
    },

   


    // 3. 路由重定向到index页面
    
  ]
})

export default router